const actions = {
  FETCH_CUSTOMER: "FETCH_CUSTOMER",
  FETCH_CUSTOMER_SUCCESS: "FETCH_CUSTOMER_SUCCESS",
  FETCH_CUSTOMER_FAILURE: "FETCH_CUSTOMER_FAILURE",

  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAILURE: "DELETE_CUSTOMER_FAILURE",
};

export default actions;
