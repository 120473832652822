const actions = {
  FETCH_TIME_TRACKING: "FETCH_TIME_TRACKING",
  FETCH_TIME_TRACKING_SUCCESS: "FETCH_TIME_TRACKING_SUCCESS",
  FETCH_TIME_TRACKING_FAILURE: "FETCH_TIME_TRACKING_FAILURE",

  ADD_TIME_TRACKING: "ADD_TIME_TRACKING",
  ADD_TIME_TRACKING_SUCCESS: "ADD_TIME_TRACKING_SUCCESS",
  ADD_TIME_TRACKING_FAILURE: "ADD_TIME_TRACKING_FAILURE",

  MAKE_XLS: "MAKE_XLS",
  MAKE_XLS_SUCCESS: "MAKE_XLS_SUCCESS",
  MAKE_XLS_FAILURE: "MAKE_XLS_FAILURE",
  RESET_XLS: "RESET_XLS",
};

export default actions;
