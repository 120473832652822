const actions = {
  FETCH_TIME_START: "FETCH_TIME_START",
  FETCH_TIME_START_SUCCESS: "FETCH_TIME_START_SUCCESS",
  FETCH_TIME_START_FAILURE: "FETCH_TIME_START_FAILURE",

  ADD_TIME_START: "ADD_TIME_START",
  ADD_TIME_START_SUCCESS: "ADD_TIME_START_SUCCESS",
  ADD_TIME_START_FAILURE: "ADD_TIME_START_FAILURE",
};

export default actions;
